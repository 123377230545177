import { CentreContainer, setIdToken } from "@project/core";
import { AuthOptions } from "@project/core/auth";
import { DecodedIdToken, SessionResult } from "@project/core/types";
import { unixTimestampToDate } from "@project/core/utils/date.helpers";
import { logError } from "@project/core/utils/errorLogger";
import { Button, Spin } from "antd";
import { isFuture } from "date-fns";
import jwt_decode from "jwt-decode";
import { GetServerSideProps, NextPage } from "next";
import { unstable_getServerSession } from "next-auth";
import { signIn, signOut } from "next-auth/react";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "store";
import parse from "url-parse";

const SignIn: NextPage<SignInProps> = ({ authorizationParams }) => {
  const dispatch = useDispatch<AppDispatch>();
  const [loading, setLoading] = useState(false);

  const handleSignIn = useCallback(async (): Promise<void> => {
    try {
      setLoading(true);
      const result = await signIn(
        "auth0",
        { callbackUrl: "/" },
        authorizationParams ? { ...authorizationParams } : undefined,
      );

      if (result?.error) {
        logError("Failed to login with Auth0.", result.error);

        // if unable to sign in, make sure we fully sign out to allow user to try again
        dispatch(setIdToken(null));
        signOut({ callbackUrl: "/api/auth/logout" });
      }
    } catch (e) {
      logError("An error occured while signing in.", e);
      setLoading(false);
    }
  }, [dispatch, authorizationParams]);

  useEffect(() => {
    // By default we want to go straight to the Auth0 sign-in page when this page loads.
    // We have the Sign-in button as a fallback if something goes wrong which allows the
    // user to manually trigger the sign-in flow.
    handleSignIn();
  }, [handleSignIn]);

  if (loading) {
    return (
      <CentreContainer>
        <Spin size="large" />
      </CentreContainer>
    );
  }

  return (
    <CentreContainer>
      <Button type="primary" onClick={handleSignIn}>
        Sign In to Sapient
      </Button>
    </CentreContainer>
  );
};

export default SignIn;

interface SignInProps {
  authorizationParams: Record<string, string> | undefined;
}

export const getServerSideProps: GetServerSideProps = async context => {
  if (context.query && typeof context.query.callbackUrl === "string") {
    /**
     * Auth0 invite links will be passed through as a callbackUrl
     * with a format such as: '/login?invitation=blah&organization=blah&organization_name=mk-dev'
     * We want to be able to pass these additional parameters through to the Auth0 sign in request
     */
    const parsedUrl = parse(context.query.callbackUrl, true);
    const query = parsedUrl.query;

    // Only pass authorizationParams if there are query params
    if (Object.keys(query).length > 0) {
      return { props: { authorizationParams: query } };
    }
  }

  try {
    const session = (await unstable_getServerSession(context.req, context.res, AuthOptions)) as SessionResult;

    if (session?.idToken) {
      const decodedIdToken = jwt_decode<DecodedIdToken>(session.idToken);
      const tokenIsNotExpired = isFuture(unixTimestampToDate(decodedIdToken.exp));
      if (tokenIsNotExpired) {
        // if the user is already authenticated and they try to access the sign-in page, push them through to the dashboard
        return {
          redirect: {
            permanent: false,
            destination: "/",
          },
        };
      }
    }
  } catch (e) {
    logError("An error occured while checking the user's session.", e);
  }

  return {
    props: {},
  };
};
